// @generated by protoc-gen-es v1.7.2 with parameter "target=js+dts"
// @generated from file password/v1/password.proto (package password.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message password.v1.StartPasswordResetRequest
 */
export const StartPasswordResetRequest = proto3.makeMessageType(
  "password.v1.StartPasswordResetRequest",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message password.v1.StartPasswordResetResponse
 */
export const StartPasswordResetResponse = proto3.makeMessageType(
  "password.v1.StartPasswordResetResponse",
  () => [
    { no: 1, name: "password_reset_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message password.v1.VerifyPasswordResetOTPRequest
 */
export const VerifyPasswordResetOTPRequest = proto3.makeMessageType(
  "password.v1.VerifyPasswordResetOTPRequest",
  () => [
    { no: 1, name: "password_reset_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "otp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message password.v1.VerifyPasswordResetOTPResponse
 */
export const VerifyPasswordResetOTPResponse = proto3.makeMessageType(
  "password.v1.VerifyPasswordResetOTPResponse",
  [],
);

/**
 * @generated from message password.v1.ResendPasswordResetOTPRequest
 */
export const ResendPasswordResetOTPRequest = proto3.makeMessageType(
  "password.v1.ResendPasswordResetOTPRequest",
  () => [
    { no: 1, name: "password_reset_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message password.v1.ResendPasswordResetOTPResponse
 */
export const ResendPasswordResetOTPResponse = proto3.makeMessageType(
  "password.v1.ResendPasswordResetOTPResponse",
  [],
);

/**
 * @generated from message password.v1.CompletePasswordResetRequest
 */
export const CompletePasswordResetRequest = proto3.makeMessageType(
  "password.v1.CompletePasswordResetRequest",
  () => [
    { no: 1, name: "password_reset_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "otp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message password.v1.CompletePasswordResetResponse
 */
export const CompletePasswordResetResponse = proto3.makeMessageType(
  "password.v1.CompletePasswordResetResponse",
  [],
);

