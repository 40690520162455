// @generated by protoc-gen-es v1.7.2 with parameter "target=js+dts"
// @generated from file whiteboard/board/v1/board_api.proto (package whiteboard.board.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { SortDirection } from "../../../base/v1/sort_pb.js";
import { Board, ListBoard } from "./board_pb.js";

/**
 * @generated from message whiteboard.board.v1.CreateBoardRequest
 */
export const CreateBoardRequest = proto3.makeMessageType(
  "whiteboard.board.v1.CreateBoardRequest",
  () => [
    { no: 1, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_clone", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message whiteboard.board.v1.CreateBoardResponse
 */
export const CreateBoardResponse = proto3.makeMessageType(
  "whiteboard.board.v1.CreateBoardResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message whiteboard.board.v1.AcquireBoardLockRequest
 */
export const AcquireBoardLockRequest = proto3.makeMessageType(
  "whiteboard.board.v1.AcquireBoardLockRequest",
  () => [
    { no: 1, name: "board_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message whiteboard.board.v1.AcquireBoardLockResponse
 */
export const AcquireBoardLockResponse = proto3.makeMessageType(
  "whiteboard.board.v1.AcquireBoardLockResponse",
  [],
);

/**
 * @generated from message whiteboard.board.v1.ReleaseBoardLockRequest
 */
export const ReleaseBoardLockRequest = proto3.makeMessageType(
  "whiteboard.board.v1.ReleaseBoardLockRequest",
  () => [
    { no: 1, name: "board_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message whiteboard.board.v1.ReleaseBoardLockResponse
 */
export const ReleaseBoardLockResponse = proto3.makeMessageType(
  "whiteboard.board.v1.ReleaseBoardLockResponse",
  [],
);

/**
 * @generated from message whiteboard.board.v1.ListBoardsRequest
 */
export const ListBoardsRequest = proto3.makeMessageType(
  "whiteboard.board.v1.ListBoardsRequest",
  () => [
    { no: 1, name: "page", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 4, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message whiteboard.board.v1.ListBoardsResponse
 */
export const ListBoardsResponse = proto3.makeMessageType(
  "whiteboard.board.v1.ListBoardsResponse",
  () => [
    { no: 1, name: "boards", kind: "message", T: ListBoard, repeated: true },
  ],
);

/**
 * @generated from message whiteboard.board.v1.GetBoardRequest
 */
export const GetBoardRequest = proto3.makeMessageType(
  "whiteboard.board.v1.GetBoardRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message whiteboard.board.v1.GetBoardResponse
 */
export const GetBoardResponse = proto3.makeMessageType(
  "whiteboard.board.v1.GetBoardResponse",
  () => [
    { no: 1, name: "board", kind: "message", T: Board },
  ],
);

/**
 * @generated from message whiteboard.board.v1.UpdateBoardDataRequest
 */
export const UpdateBoardDataRequest = proto3.makeMessageType(
  "whiteboard.board.v1.UpdateBoardDataRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "preview_image", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 4, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message whiteboard.board.v1.UpdateBoardDataResponse
 */
export const UpdateBoardDataResponse = proto3.makeMessageType(
  "whiteboard.board.v1.UpdateBoardDataResponse",
  [],
);

/**
 * @generated from message whiteboard.board.v1.DeleteBoardRequest
 */
export const DeleteBoardRequest = proto3.makeMessageType(
  "whiteboard.board.v1.DeleteBoardRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "delete_files", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message whiteboard.board.v1.DeleteBoardResponse
 */
export const DeleteBoardResponse = proto3.makeMessageType(
  "whiteboard.board.v1.DeleteBoardResponse",
  [],
);

/**
 * @generated from message whiteboard.board.v1.GetBoardRoomsRequest
 */
export const GetBoardRoomsRequest = proto3.makeMessageType(
  "whiteboard.board.v1.GetBoardRoomsRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message whiteboard.board.v1.GetBoardRoomsResponse
 */
export const GetBoardRoomsResponse = proto3.makeMessageType(
  "whiteboard.board.v1.GetBoardRoomsResponse",
  () => [
    { no: 1, name: "room_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message whiteboard.board.v1.UpdateBoardRoomsRequest
 */
export const UpdateBoardRoomsRequest = proto3.makeMessageType(
  "whiteboard.board.v1.UpdateBoardRoomsRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "to_add_room_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "to_remove_room_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message whiteboard.board.v1.UpdateBoardRoomsResponse
 */
export const UpdateBoardRoomsResponse = proto3.makeMessageType(
  "whiteboard.board.v1.UpdateBoardRoomsResponse",
  [],
);

