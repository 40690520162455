// @generated by protoc-gen-es v1.7.2 with parameter "target=js+dts"
// @generated from file affiliate/v1/affiliate_api.proto (package affiliate.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Affiliate } from "./affiliate_pb.js";

/**
 * @generated from message affiliate.v1.AddAffiliateRequest
 */
export const AddAffiliateRequest = proto3.makeMessageType(
  "affiliate.v1.AddAffiliateRequest",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message affiliate.v1.AddAffiliateResponse
 */
export const AddAffiliateResponse = proto3.makeMessageType(
  "affiliate.v1.AddAffiliateResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message affiliate.v1.RemoveAffiliateRequest
 */
export const RemoveAffiliateRequest = proto3.makeMessageType(
  "affiliate.v1.RemoveAffiliateRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message affiliate.v1.RemoveAffiliateResponse
 */
export const RemoveAffiliateResponse = proto3.makeMessageType(
  "affiliate.v1.RemoveAffiliateResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message affiliate.v1.ListAffiliatesRequest
 */
export const ListAffiliatesRequest = proto3.makeMessageType(
  "affiliate.v1.ListAffiliatesRequest",
  [],
);

/**
 * @generated from message affiliate.v1.ListAffiliatesResponse
 */
export const ListAffiliatesResponse = proto3.makeMessageType(
  "affiliate.v1.ListAffiliatesResponse",
  () => [
    { no: 1, name: "affiliates", kind: "message", T: Affiliate, repeated: true },
  ],
);

