// @generated by protoc-gen-es v1.7.2 with parameter "target=js+dts"
// @generated from file aggregate/v1/aggregate_api.proto (package aggregate.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { User } from "../../base/v1/user_pb.js";
import { Affiliate } from "../../affiliate/v1/affiliate_pb.js";
import { Room } from "../../room/v1/room_pb.js";

/**
 * @generated from message aggregate.v1.GetUserAggregateProfileRequest
 */
export const GetUserAggregateProfileRequest = proto3.makeMessageType(
  "aggregate.v1.GetUserAggregateProfileRequest",
  () => [
    { no: 1, name: "notification_created_on_after", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message aggregate.v1.GetUserAggregateProfileResponse
 */
export const GetUserAggregateProfileResponse = proto3.makeMessageType(
  "aggregate.v1.GetUserAggregateProfileResponse",
  () => [
    { no: 1, name: "user", kind: "message", T: User },
    { no: 2, name: "notifications_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "last_notification_date", kind: "message", T: Timestamp },
    { no: 4, name: "max_boards_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "created_boards_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "my_affiliates", kind: "message", T: Affiliate, repeated: true },
  ],
);

/**
 * @generated from message aggregate.v1.GetBoardAggregateRoomsRequest
 */
export const GetBoardAggregateRoomsRequest = proto3.makeMessageType(
  "aggregate.v1.GetBoardAggregateRoomsRequest",
  () => [
    { no: 1, name: "board_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message aggregate.v1.GetBoardAggregateRoomsResponse
 */
export const GetBoardAggregateRoomsResponse = proto3.makeMessageType(
  "aggregate.v1.GetBoardAggregateRoomsResponse",
  () => [
    { no: 1, name: "rooms", kind: "message", T: Room, repeated: true },
    { no: 2, name: "room_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

