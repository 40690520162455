// @generated by protoc-gen-es v1.7.2 with parameter "target=js+dts"
// @generated from file template/v1/template.proto (package template.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Orientation, Preset, Sport } from "../../base/v1/whiteboard_pb.js";

/**
 * @generated from message template.v1.Template
 */
export const Template = proto3.makeMessageType(
  "template.v1.Template",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 4, name: "preview_image_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "preset", kind: "enum", T: proto3.getEnumType(Preset) },
    { no: 6, name: "orientation", kind: "enum", T: proto3.getEnumType(Orientation) },
    { no: 7, name: "sport", kind: "enum", T: proto3.getEnumType(Sport) },
    { no: 8, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "created_at", kind: "message", T: Timestamp },
    { no: 10, name: "updated_at", kind: "message", T: Timestamp },
    { no: 11, name: "sequence_no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message template.v1.ListTemplate
 */
export const ListTemplate = proto3.makeMessageType(
  "template.v1.ListTemplate",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "preview_image_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "preset", kind: "enum", T: proto3.getEnumType(Preset) },
    { no: 5, name: "orientation", kind: "enum", T: proto3.getEnumType(Orientation) },
    { no: 6, name: "sport", kind: "enum", T: proto3.getEnumType(Sport) },
    { no: 7, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "created_at", kind: "message", T: Timestamp },
    { no: 9, name: "updated_at", kind: "message", T: Timestamp },
    { no: 10, name: "sequence_no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

