// @generated by protoc-gen-connect-query v1.3.1 with parameter "target=ts"
// @generated from file register/v1/register.proto (package register.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { CompleteRegistrationRequest, CompleteRegistrationResponse, ResendRegistrationOTPRequest, ResendRegistrationOTPResponse, StartRegistrationRequest, StartRegistrationResponse, VerifyRegistrationOTPRequest, VerifyRegistrationOTPResponse } from "./register_pb.js";

/**
 * StartRegistration start a new gistration flow
 *
 * @generated from rpc register.v1.RegisterAPI.StartRegistration
 */
export const startRegistration = {
  localName: "startRegistration",
  name: "StartRegistration",
  kind: MethodKind.Unary,
  I: StartRegistrationRequest,
  O: StartRegistrationResponse,
  service: {
    typeName: "register.v1.RegisterAPI"
  }
} as const;

/**
 * VerifyRegistrationOTP validates an OTP sent for registration flow
 *
 * @generated from rpc register.v1.RegisterAPI.VerifyRegistrationOTP
 */
export const verifyRegistrationOTP = {
  localName: "verifyRegistrationOTP",
  name: "VerifyRegistrationOTP",
  kind: MethodKind.Unary,
  I: VerifyRegistrationOTPRequest,
  O: VerifyRegistrationOTPResponse,
  service: {
    typeName: "register.v1.RegisterAPI"
  }
} as const;

/**
 * ResendRegistrationOTP resebds the OTP
 *
 * @generated from rpc register.v1.RegisterAPI.ResendRegistrationOTP
 */
export const resendRegistrationOTP = {
  localName: "resendRegistrationOTP",
  name: "ResendRegistrationOTP",
  kind: MethodKind.Unary,
  I: ResendRegistrationOTPRequest,
  O: ResendRegistrationOTPResponse,
  service: {
    typeName: "register.v1.RegisterAPI"
  }
} as const;

/**
 * CompleteRegistration completes the registration process, creates user accopunt and logs them in
 *
 * @generated from rpc register.v1.RegisterAPI.CompleteRegistration
 */
export const completeRegistration = {
  localName: "completeRegistration",
  name: "CompleteRegistration",
  kind: MethodKind.Unary,
  I: CompleteRegistrationRequest,
  O: CompleteRegistrationResponse,
  service: {
    typeName: "register.v1.RegisterAPI"
  }
} as const;
