// @generated by protoc-gen-es v1.7.2 with parameter "target=js+dts"
// @generated from file room/v1/room.proto (package room.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { User } from "../../base/v1/user_pb.js";
import { AccessType } from "../../base/v1/access_pb.js";

/**
 * @generated from message room.v1.Room
 */
export const Room = proto3.makeMessageType(
  "room.v1.Room",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "users", kind: "message", T: RoomUser, repeated: true },
    { no: 5, name: "is_shared_to_me", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "owner", kind: "message", T: User },
  ],
);

/**
 * @generated from message room.v1.RoomUser
 */
export const RoomUser = proto3.makeMessageType(
  "room.v1.RoomUser",
  () => [
    { no: 1, name: "user", kind: "message", T: User },
    { no: 2, name: "access_type", kind: "enum", T: proto3.getEnumType(AccessType) },
  ],
);

