// @generated by protoc-gen-es v1.7.2 with parameter "target=js+dts"
// @generated from file base/v1/media.proto (package base.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum base.v1.MediaType
 */
export const MediaType = proto3.makeEnum(
  "base.v1.MediaType",
  [
    {no: 0, name: "MEDIA_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "MEDIA_TYPE_IMAGE", localName: "IMAGE"},
  ],
);

/**
 * @generated from message base.v1.Media
 */
export const Media = proto3.makeMessageType(
  "base.v1.Media",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "content_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "meta_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "file_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "type", kind: "enum", T: proto3.getEnumType(MediaType) },
    { no: 8, name: "created_at", kind: "message", T: Timestamp },
    { no: 9, name: "updated_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message base.v1.UploadMedia
 */
export const UploadMedia = proto3.makeMessageType(
  "base.v1.UploadMedia",
  () => [
    { no: 1, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "content_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "meta_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "type", kind: "enum", T: proto3.getEnumType(MediaType) },
  ],
);

