// @generated by protoc-gen-connect-query v1.3.1 with parameter "target=ts"
// @generated from file aggregate/v1/aggregate_api.proto (package aggregate.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { GetBoardAggregateRoomsRequest, GetBoardAggregateRoomsResponse, GetUserAggregateProfileRequest, GetUserAggregateProfileResponse } from "./aggregate_api_pb.js";

/**
 * @generated from rpc aggregate.v1.AggregateAPI.GetUserAggregateProfile
 */
export const getUserAggregateProfile = {
  localName: "getUserAggregateProfile",
  name: "GetUserAggregateProfile",
  kind: MethodKind.Unary,
  I: GetUserAggregateProfileRequest,
  O: GetUserAggregateProfileResponse,
  service: {
    typeName: "aggregate.v1.AggregateAPI"
  }
} as const;

/**
 * @generated from rpc aggregate.v1.AggregateAPI.GetBoardAggregateRooms
 */
export const getBoardAggregateRooms = {
  localName: "getBoardAggregateRooms",
  name: "GetBoardAggregateRooms",
  kind: MethodKind.Unary,
  I: GetBoardAggregateRoomsRequest,
  O: GetBoardAggregateRoomsResponse,
  service: {
    typeName: "aggregate.v1.AggregateAPI"
  }
} as const;
