// @generated by protoc-gen-connect-query v1.3.1 with parameter "target=ts"
// @generated from file password/v1/password.proto (package password.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { CompletePasswordResetRequest, CompletePasswordResetResponse, ResendPasswordResetOTPRequest, ResendPasswordResetOTPResponse, StartPasswordResetRequest, StartPasswordResetResponse, VerifyPasswordResetOTPRequest, VerifyPasswordResetOTPResponse } from "./password_pb.js";

/**
 * StartPasswordReset start a new gistration flow
 *
 * @generated from rpc password.v1.PasswordAPI.StartPasswordReset
 */
export const startPasswordReset = {
  localName: "startPasswordReset",
  name: "StartPasswordReset",
  kind: MethodKind.Unary,
  I: StartPasswordResetRequest,
  O: StartPasswordResetResponse,
  service: {
    typeName: "password.v1.PasswordAPI"
  }
} as const;

/**
 * VerifyPasswordResetOTP validates an OTP sent for registration flow
 *
 * @generated from rpc password.v1.PasswordAPI.VerifyPasswordResetOTP
 */
export const verifyPasswordResetOTP = {
  localName: "verifyPasswordResetOTP",
  name: "VerifyPasswordResetOTP",
  kind: MethodKind.Unary,
  I: VerifyPasswordResetOTPRequest,
  O: VerifyPasswordResetOTPResponse,
  service: {
    typeName: "password.v1.PasswordAPI"
  }
} as const;

/**
 * ResendPasswordResetOTP resends OTP
 *
 * @generated from rpc password.v1.PasswordAPI.ResendPasswordResetOTP
 */
export const resendPasswordResetOTP = {
  localName: "resendPasswordResetOTP",
  name: "ResendPasswordResetOTP",
  kind: MethodKind.Unary,
  I: ResendPasswordResetOTPRequest,
  O: ResendPasswordResetOTPResponse,
  service: {
    typeName: "password.v1.PasswordAPI"
  }
} as const;

/**
 * CompletePasswordReset completes the registration process, creates user accopunt and logs them in
 *
 * @generated from rpc password.v1.PasswordAPI.CompletePasswordReset
 */
export const completePasswordReset = {
  localName: "completePasswordReset",
  name: "CompletePasswordReset",
  kind: MethodKind.Unary,
  I: CompletePasswordResetRequest,
  O: CompletePasswordResetResponse,
  service: {
    typeName: "password.v1.PasswordAPI"
  }
} as const;
