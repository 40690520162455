// @generated by protoc-gen-es v1.7.2 with parameter "target=js+dts"
// @generated from file chat/v1/chat_api.proto (package chat.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Chat } from "./chat_pb.js";

/**
 * @generated from message chat.v1.CreateChatRequest
 */
export const CreateChatRequest = proto3.makeMessageType(
  "chat.v1.CreateChatRequest",
  () => [
    { no: 1, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chat.v1.CreateChatResponse
 */
export const CreateChatResponse = proto3.makeMessageType(
  "chat.v1.CreateChatResponse",
  [],
);

/**
 * @generated from message chat.v1.ListChatsToUserRequest
 */
export const ListChatsToUserRequest = proto3.makeMessageType(
  "chat.v1.ListChatsToUserRequest",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chat.v1.ListChatsToUserResponse
 */
export const ListChatsToUserResponse = proto3.makeMessageType(
  "chat.v1.ListChatsToUserResponse",
  () => [
    { no: 1, name: "chats", kind: "message", T: Chat, repeated: true },
  ],
);

/**
 * @generated from message chat.v1.ListChatsRequest
 */
export const ListChatsRequest = proto3.makeMessageType(
  "chat.v1.ListChatsRequest",
  [],
);

/**
 * @generated from message chat.v1.ListChatsResponse
 */
export const ListChatsResponse = proto3.makeMessageType(
  "chat.v1.ListChatsResponse",
  () => [
    { no: 1, name: "chats", kind: "message", T: Chat, repeated: true },
  ],
);

