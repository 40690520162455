// @generated by protoc-gen-es v1.7.2 with parameter "target=js+dts"
// @generated from file room/v1/room_api.proto (package room.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Room } from "./room_pb.js";
import { AccessType } from "../../base/v1/access_pb.js";

/**
 * @generated from message room.v1.CreateRoomRequest
 */
export const CreateRoomRequest = proto3.makeMessageType(
  "room.v1.CreateRoomRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "org_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message room.v1.CreateRoomResponse
 */
export const CreateRoomResponse = proto3.makeMessageType(
  "room.v1.CreateRoomResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message room.v1.ListRoomsRequest
 */
export const ListRoomsRequest = proto3.makeMessageType(
  "room.v1.ListRoomsRequest",
  [],
);

/**
 * @generated from message room.v1.ListRoomsResponse
 */
export const ListRoomsResponse = proto3.makeMessageType(
  "room.v1.ListRoomsResponse",
  () => [
    { no: 1, name: "rooms", kind: "message", T: Room, repeated: true },
  ],
);

/**
 * @generated from message room.v1.ListInvitedRoomsRequest
 */
export const ListInvitedRoomsRequest = proto3.makeMessageType(
  "room.v1.ListInvitedRoomsRequest",
  [],
);

/**
 * @generated from message room.v1.ListInvitedRoomsResponse
 */
export const ListInvitedRoomsResponse = proto3.makeMessageType(
  "room.v1.ListInvitedRoomsResponse",
  () => [
    { no: 1, name: "rooms", kind: "message", T: Room, repeated: true },
  ],
);

/**
 * @generated from message room.v1.GetRoomRequest
 */
export const GetRoomRequest = proto3.makeMessageType(
  "room.v1.GetRoomRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message room.v1.GetRoomResponse
 */
export const GetRoomResponse = proto3.makeMessageType(
  "room.v1.GetRoomResponse",
  () => [
    { no: 1, name: "room", kind: "message", T: Room },
  ],
);

/**
 * @generated from message room.v1.AddRoomUserRequest
 */
export const AddRoomUserRequest = proto3.makeMessageType(
  "room.v1.AddRoomUserRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "access_type", kind: "enum", T: proto3.getEnumType(AccessType) },
  ],
);

/**
 * @generated from message room.v1.AddRoomUserResponse
 */
export const AddRoomUserResponse = proto3.makeMessageType(
  "room.v1.AddRoomUserResponse",
  [],
);

/**
 * @generated from message room.v1.DeleteRoomUserRequest
 */
export const DeleteRoomUserRequest = proto3.makeMessageType(
  "room.v1.DeleteRoomUserRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message room.v1.DeleteRoomUserResponse
 */
export const DeleteRoomUserResponse = proto3.makeMessageType(
  "room.v1.DeleteRoomUserResponse",
  [],
);

/**
 * @generated from message room.v1.DeleteRoomRequest
 */
export const DeleteRoomRequest = proto3.makeMessageType(
  "room.v1.DeleteRoomRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message room.v1.DeleteRoomResponse
 */
export const DeleteRoomResponse = proto3.makeMessageType(
  "room.v1.DeleteRoomResponse",
  [],
);

/**
 * @generated from message room.v1.UpdateUserRoomPermissionRequest
 */
export const UpdateUserRoomPermissionRequest = proto3.makeMessageType(
  "room.v1.UpdateUserRoomPermissionRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "access_type", kind: "enum", T: proto3.getEnumType(AccessType) },
  ],
);

/**
 * @generated from message room.v1.UpdateUserRoomPermissionResponse
 */
export const UpdateUserRoomPermissionResponse = proto3.makeMessageType(
  "room.v1.UpdateUserRoomPermissionResponse",
  [],
);

