// @generated by protoc-gen-es v1.7.2 with parameter "target=js+dts"
// @generated from file diary/v1/diary.proto (package diary.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message diary.v1.DiaryPage
 */
export const DiaryPage = proto3.makeMessageType(
  "diary.v1.DiaryPage",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created_at", kind: "message", T: Timestamp },
    { no: 5, name: "updated_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message diary.v1.DiaryPageInfo
 */
export const DiaryPageInfo = proto3.makeMessageType(
  "diary.v1.DiaryPageInfo",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created_at", kind: "message", T: Timestamp },
  ],
);

